import * as React from "react"
import { Link } from "gatsby"

import IMG_01 from "../images/company.jpeg"
import IMG_02 from "../images/contact.jpeg"

import * as styles from "../styles/global/information.module.scss"

const Information = () => {

  return (
    <section className={styles.cInformation}>
        <div className={`${styles.inner} cContainer`}>
          <p className={`${styles.mainTtl} alphabet`}>INFORMATION</p>
          <div className={styles.content}>
            <div className={styles.head}>
              <h2 className="alphabet">-LINKS</h2>
            </div>
            <div className={styles.body}>
              <Link to="/company">
                <div className={styles.thumb} style={{ backgroundImage: `linear-gradient(rgba(76, 146, 187, 0.39), rgb(60 117 228 / 35%)),url(${IMG_01})` }}></div>
                <p>
                  <span className="alphabet">ABOUT US</span><br/>
                  会社情報
                </p>
              </Link>
              <Link to="/company/#contact">
                <div className={styles.thumb} style={{ backgroundImage: `linear-gradient(rgba(76, 146, 187, 0.39), rgb(60 117 228 / 35%)),url(${IMG_02})` }}></div>
                <p>
                  <span className="alphabet">CONTACT US</span><br/>
                  お問い合わせ
                </p>
              </Link>
            </div>
          </div>
        </div>
      </section>
  )
}

export default Information
