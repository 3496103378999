import * as React from "react"
//import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Information from "../components/information"

import * as styles from "../styles/policy.module.scss"

const Policy = () => {
  return (
    <Layout>
      <Seo title="個人情報の取扱いについて" description="このページは「個人情報の取扱い」に関するページです。" />
      <section className="cPageHeader">
        <div className="inner cContainer">
          <div className="content">
            <h2>
              <span className="alphabet">PRIVACY POLICY</span><br />
              個人情報の取扱いについて
            </h2>
          </div>
        </div>
      </section>

      <section className={styles.policy}>
        <div className={`${styles.inner} cContainer`}>
          <div className={styles.content}>
            <p>
            ブロックチェーンコネクト（以下、「当社」という。）は，当社にご提供頂いた個人の氏名・住所等、その他の個人を識別することのできる情報について以下のとおりプライバシーポリシー（以下、「本ポリシー」という。）を定めます。
            </p>
            <h2>事業者情報</h2>
            <p>
              法人名：ブロックチェーンコネクト<br />
              所在地：〒192-0045　東京都八王子市大和田町5-24-8<br />
              代表取締役社長 CEO：KAZUKI KIUCHI
            </p>
            <h2>個人情報の利用目的</h2>
            <p>
              取得した閲覧・購買履歴等の情報を分析し、ユーザー別に適した商品・サービスをお知らせするために利用します。また、取得した閲覧・購買履歴等の情報は、結果をスコア化した上で当該スコアを第三者へ提供します。
            </p>
            <h2>個人データを安全に管理するための措置</h2>
            <p>
              当社は、個人情報の保護に関して、個人情報の保護に関する法律及びその他の個人情報保護に関する法令を遵守、適切な対策を実施し、当社の取り扱う個人情報の漏えい、滅失又はき損の防止その他の個人情報の安全管理のために必要かつ適切な措置を講ずるものとします。
            </p>
            <h2>個人データの第三者提供について</h2>
            <p>
              当社は、個人情報の取扱いの全部又は一部を第三者に委託する場合、その適格性を十分に審査し、その取扱いを委託された個人情報の安全管理が図られるよう、委託を受けた者に対する必要かつ適切な監督を行うこととします。
            </p>
            <h2>保有個人データの開示、訂正</h2>
            <p>
              当社は本人から個人情報の開示を求められたときには、遅滞なく本人に対しこれを開示します。個人情報の訂正、追加、削除、第三者への提供の停止を希望される方は以下の宛先へご請求ください。
            </p>
            <p>
              事業者情報をご参照ください。
            </p>
            <h2>個人情報取り扱いに関する相談や苦情の連絡先</h2>
            <p>
              当社の個人情報の取り扱いに関するご質問やご不明点、苦情、その他のお問い合わせは以下よりご連絡ください。<br />
              事業者情報をご参照ください。
            </p>
            <h2>SSL（Secure Socket Layer）について</h2>
            <p>
              当社のWebサイトはSSLに対応しており、WebブラウザとWebサーバーとの通信を暗号化しています。ユーザーが入力する氏名や住所、電話番号などの個人情報は自動的に暗号化されます。
            </p>
            <h2>cookieについて</h2>
            <p>
              cookieとは、WebサーバーからWebブラウザに送信されるデータのことです。Webサーバーがcookieを参照することでユーザーのパソコンを識別でき、効率的に当社Webサイトを利用することができます。当社Webサイトがcookieとして送るファイルは、個人を特定するような情報は含んでおりません。<br />
              お使いのWebブラウザの設定により、cookieを無効にすることも可能です。
            </p>
            <h2>免責事項</h2>
            <p>
              当社Webサイトに掲載されている情報の正確性には万全を期していますが、利用者が当社Webサイトの情報を用いて行う一切の行為に関して、一切の責任を負わないものとします。<br />
              当社は、利用者が当社Webサイトを利用したことにより生じた利用者の損害及び利用者が第三者に与えた損害に関して、一切の責任を負わないものとします。
            </p>
            <h2>著作権・肖像権</h2>
            <p>
              当社Webサイト内の文章や画像、すべてのコンテンツは著作権・肖像権等により保護されています。無断での使用や転用は禁止されています。
            </p>
            <h2>リンク</h2>
            <p>
              当社Webサイトへのリンクは、自由に設置していただいて構いません。ただし、Webサイトの内容等によってはリンクの設置をお断りすることがあります。
            </p>
          </div>
        </div>
      </section>
      
    </Layout>
  )
}

export default Policy